/* eslint-disable */
import { Popover } from '@headlessui/react'
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <Popover className="relative bg-gray-50">
    <>
      <div className="py-10 bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Lorem ipsum Lorem ipsum?</span>
            <span className="block text-blue-600">Lorem ipsum ipsum.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to=""
                className="inline-flex duration-300 items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Lorem
            </Link>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow-sm">
              <Link
                to="mailto:hello@skyblock.tools"
                className="bg-blue-50 duration-300inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 hover:bg-blue-100"
              >
                hello@skyblock.tools
            </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 rounded-t-lg">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
            <div className="md:max-w-md lg:col-span-2">
              <Link
                to="/"
                aria-label="Go home"
                title="Company"
                className="inline-flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40"><path fill="none" d="M0 0h24v24H0z" /><path d="M17 4a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H7a6 6 0 0 1-6-6v-4a6 6 0 0 1 6-6h10zm0 2H7a4 4 0 0 0-3.995 3.8L3 10v4a4 4 0 0 0 3.8 3.995L7 18h10a4 4 0 0 0 3.995-3.8L21 14v-4a4 4 0 0 0-3.8-3.995L17 6zm-7 3v2h2v2H9.999L10 15H8l-.001-2H6v-2h2V9h2zm8 4v2h-2v-2h2zm-2-4v2h-2V9h2z" fill="rgba(255,255,255,1)" /></svg>
                <span className="ml-2 text-xl font-bold tracking-wide text-white capitalize">
                  Skyblock Tools
            </span>
              </Link>
              <div className="mt-4 lg:max-w-sm">
                <p className="text-sm text-gray-800">
                </p>
                <p className="mt-4 text-sm text-gray-800">

                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div>
                <p className="font-semibold tracking-wide text-gray-400">
                  Community
            </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="http://status.skyblock.tools/"
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Service Status
                </a>
                  </li>
                  <li>
                    <Link
                      to="/docs"
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Knowledgebase
                </Link>
                  </li>

                  <li>
                    <a
                      href="https://discord.gg/HWrFRhb6Hh"
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Discord Server
                </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-gray-400">
                  Tools
            </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href=""
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Placeholder
                </a>
                  </li>
                  <li>
                    <Link
                      to=""
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Placeholder
                </Link>
                  </li>
                  <li>
                    <Link
                      to=""
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Placeholder
                </Link>
                  </li>


                </ul>
              </div>

              <div>
                <p className="font-semibold tracking-wide text-gray-400">Client</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href=""
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Placeholder
                </a> 
                  </li>
                  <li>
                    <a
                      href=""
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Placeholder
                </a>
                  </li>
                  <li>
                    <a
                      href=""
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Placeholder
                </a>
                  </li>


                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-gray-400">Legal</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link
                      to="/docs/security/terms"
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Terms of Service
                </Link>
                  </li>
                  <li>
                    <Link
                      to="/docs/security/privacy"
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Privacy Policy
                </Link>
                  </li>
                  <li>
                    <Link
                      to="/docs/security/data"
                      className="text-gray-500 transition-colors duration-300 hover:text-blue-300"
                    >
                      Data Policy
                </Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between pt-5 pb-10 border-t border-gray-400 sm:flex-row">
            <p className="text-sm text-gray-400">
              © Copyright 2021 SkyblockTools. All rights reserved.
        </p>
            <div className="flex items-center mt-4 space-x-4 sm:mt-0">
              <a
                href=""
                className="text-gray-500 transition-colors duration-300 hover:text-blue-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
              </a>
              <a
                href="https://github.com/skyblock-tools"
                className="text-gray-500 transition-colors duration-300 hover:text-purple-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />            </svg>
              </a>
              <a
                className="cursor-pointer text-gray-500 transition-colors duration-300 hover:text-white"
                
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  });
                }}
              ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                </svg></a>

            </div>
          </div>
        </div>
      </div>
    </>

  </Popover>
);
export default Footer;

/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
const NotFoundPage = () => (
  <div className="bg-gray-50">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto sm:text-center lg:max-w-2xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-900 uppercase rounded-full bg-green-400">
              Error 404
              </p>
            </div>
            <h2 className="max-w-lg -mb-5 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">It looks like you've got </span>
              </span>{' '}
              lost, buddy.
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
            
            </p>
          </div>
          <p className="max-w-2xl mb-5 text-base text-gray-700 sm:mx-auto">
          Of course, you can <Link className="text-blue-700 font-semibold" to="/">go home</Link>, but since you're here, <br></br><span>let's first enjoy our cute cat tessa:</span>
          </p>
          <div className=" transition-shadow duration-300 ">
            <img
              className="object-cover h-80 rounded shadow-lg mx-auto "
              src="https://cdn.skyblock.tools/img/headers/tessa.webp"
              alt=""
            />
          </div>
       
    
        </div>
      </div>
      </div>
    );
    export default NotFoundPage;
 /* eslint-disable */
import Typist from 'react-typist';
import { Link } from 'react-router-dom';
import TextTransition, { presets } from "react-text-transition";
import { Popover } from '@headlessui/react'
import {
  ShieldCheckIcon,
} from '@heroicons/react/outline'
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon } from '@heroicons/react/outline'
import React from 'react';
const TEXTS = [
  "Skyhouse",
  "Some punchline",
  "Another punchline"
];

const features = [
    {
      name: 'GlobeAltIcon',
      description:
        "Lorem ipsum may be used as a placeholder before final copy is available.",
      icon: GlobeAltIcon,
    },
    {
      name: 'ShieldCheckIcon',
      description:
      "Lorem ipsum may be used as a placeholder before final copy is available.",
      icon: ShieldCheckIcon,
    },
    {
      name: 'LightningBoltIcon',
      description:
      "Lorem ipsum may be used as a placeholder before final copy is available.",
      icon: LightningBoltIcon,
    },
    {
      name: 'AnnotationIcon',
      description:
      "Lorem ipsum may be used as a placeholder before final copy is available.",
      icon: AnnotationIcon,
    },
  ]

  const Home = () => {
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
      const intervalId = setInterval(() =>
        setIndex(index => index + 1),
        8000 // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);

    return (
    <Popover className="relative bg-gray-50">
      <>
<div className="relative px-4 pt-16 mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full">
      <div className="max-w-xl mx-auto lg:max-w-screen-xl">
        <div className="mb-16 lg:max-w-lg lg:mb-0">
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-900 uppercase rounded-full bg-green-400">
              Brand new
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Introducing
              <br className="hidden md:block" />
              {' '}
              <span className="inline-block text-blue-600">
              <TextTransition
        text={ TEXTS[index % TEXTS.length] }
        springConfig={ presets.default }
      />
              </span>
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
            Lorem ipsum may be used as a placeholder before final copy is available.

            </p>
          </div>
          <div className="flex items-center">
            <Link
              to="/start"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-md shadow-md bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none"
            >
              Get started
            </Link>
            <Link
              to="/docs/learn"
              aria-label=""
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-blue-700 transition duration-200 rounded-md bg-blue-100 hover:bg-blue-200 focus:shadow-outline focus:outline-none"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center h-full overflow-hidden lg:w-2/3 xl:w-1/2 lg:absolute lg:justify-start lg:bottom-0 lg:right-4 lg:items-end">
        <img
          src="https://cdn.gamesrv.io/img/headers/expand-4.png"
          className="object-cover object-top w-full max-w-xl invisible mb-3 rounded-t rounded-b-sm shadow lg:visible md:mb-8 md:visible lg:ml-64 xl:visible xl:ml-8 lg:mb-24 xl:mb-8 lg:h-auto lg:max-w-screen-md"
          alt=""
        />
      </div>
    </div>
    <div className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">how it breaks down</h2>
          <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <Typist cursor={{ hideWhenDone: true}}>Tired of old hardware?  <Typist.Backspace count={15} delay={1500} /><Typist.Delay ms={500} />being offline? <Typist.Backspace count={15} delay={1500} /><Typist.Delay ms={500} />poor performance? </Typist>
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Our entire infrastructure is made up of completely robust high-performance dedicated servers running the latest Ryzen processors.</p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col bg-white border rounded-md shadow-sm-sm md:justify-center lg:flex-row">
        <div className="flex flex-col justify-between p-5 border-b sm:p-10 lg:border-b-0 lg:border-r lg:w-1/2">
          <div>
            <p className="mb-2 text-xs font-semibold tracking-wide uppercase">
            Lorem ipsum
            </p>
            <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
            Mod Lol
            </h5>
            <p className="mb-6 text-base text-gray-700 md:text-lg sm:mb-8">
            Deploy a gameserver on your favorite game in seconds, startup your own community or play with your friends.
            </p>
          </div>
          <div className="flex items-center">
          <Link
              to=""
              aria-label=""
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-md shadow-sm bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none"
            >
              Get started
            </Link>
            <Link
              to=""
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-blue-700"
            >
              Learn more
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 sm:p-10 lg:w-1/2">
          <div>
            <p className="mb-2 text-xs font-semibold tracking-wide uppercase">
            Lorem ipsum
            </p>
            <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
            Skyhouse+
            </h5>
            <p className="mb-6 text-base text-gray-700 md:text-lg sm:mb-8">
            Not powerful enough? Try a virtual private server, deployed within seconds with the operating system of your choice.
            </p>
          </div>
          <div className="flex items-center">
          <Link
              to="/hosting/vps"
              aria-label=""
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-md shadow-sm bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none"
            >
              Show Plans
            </Link>
            <Link
              to="/docs/learn/vps"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-blue-700"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 rounded-md">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-900 uppercase rounded-full bg-green-400">
            Status Info
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="9012817d-af60-45bb-9786-89646bc1c945"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#9012817d-af60-45bb-9786-89646bc1c945)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Locations</span>
          </span>{' '}
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
        All of our locations are protected by advanced anti-DDoS mitigation and are equipped with the latest Ryzen and Intel Processors to ensure a fast and quality experience.
        </p>
      </div>
      <div className="max-w-lg space-y-3 sm:mx-auto lg:max-w-xl">
        <div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-white">
        <div className="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 transition-colors duration-200 text-blue-600  sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg> </div>
          <span className="text-gray-800 transition-colors duration-200 ">
          Oregon, United States
          </span>
        </div>
        {/*<div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-white">
        <div className="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 transition-colors duration-200 text-blue-600  sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg> </div>
          <span className="text-gray-800 transition-colors duration-200 ">
          Montreal, Canada
          </span>
            </div> */}
        <div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-white">
        <div className="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 transition-colors duration-200 text-blue-600  sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg> </div>
          <span className="text-gray-800 transition-colors duration-200 ">
          Germany, Europe         </span>
        </div>
        <div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-white">
        <div className="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 transition-colors duration-200 text-blue-600  sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg> </div>
          <span className="text-gray-800 transition-colors duration-200 ">
          Finland, Europe          </span>
        </div>
        <div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-gray-100">
        <div className="mr-2 text-gray-600 italic text-sm">
        Soon </div>
          <span className="text-gray-700 transition-colors duration-200 ">
          France, Europe          </span>
        </div>
        <div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-gray-100">
        <div className="mr-2 text-gray-600 italic text-sm">
        Soon </div>
          <span className="text-gray-700 transition-colors duration-200 ">
          Sydney, Australia          </span>
        </div>
        <div className="flex items-center p-2 transition-colors duration-200 border rounded-md shadow-sm group bg-gray-100">
        <div className="mr-2 text-gray-600 italic text-sm">
        Soon </div>
          <span className="text-gray-700 transition-colors duration-200 ">
          Dallas, United States         </span>
        </div>
      </div>
    </div>
  </>

    </Popover>
    );
  };
  export default Home;

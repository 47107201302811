/* eslint-disable */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';

import Home from './components/pages/Home.js';
import About from './components/pages/About.js';
import Billing from './components/pages/Billing.js';

/* 404 */
import NotFoundPage from './components/errors/404.js';

/* elements */
import ScrollPage from './components/helpers/Scroll.js';
import Header from './components/elements/Header.js';
import Footer from './components/elements/Footer.js';

const App = () => (
  <Router>
    <ScrollPage>
      <Header />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/billing' component={Billing} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <Footer />
    </ScrollPage>
  </Router>
);
export default App;

/* eslint-disable */
import { Popover } from '@headlessui/react'
import React from 'react';

const About = () => (
    <Popover className="relative bg-gray-50">
        <>

        </>
        )
    </Popover>
);
export default About;
